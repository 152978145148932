<template>
  <div id="oomPage">
    <div class="tRowTopMain" :class="{tRowTopMainnonMobile : isMobile(true) }">
      <MiniMenu 
      :config="config"
      :seasonCode="id"
      :tour="tour"
      />
    </div>
    <template v-if="isMobile(true)">
      <div class="titleLine">
        <b-container class="topSec">
          <template >
            <div class="titleBackGround">
              <b-row class="topSecrow">
                <b-col md='6' cols='12'>
                  <h2 class="fullName">
                    {{ data.full_name }} 
                  </h2>
                </b-col>
              </b-row>
            </div>
            <b-dropdown :text="this.id + ' Season'">
              <b-dropdown-item
                v-on:click="changeYear"
                v-for="(seasons,
                index) in tm_params.seasons.seasons_entry.slice().reverse()"
                :key="index"
                :title="seasons.code"
                :year="seasons.code"
                >{{ seasons.desc }}</b-dropdown-item
              >
            </b-dropdown>
            <div
              v-if="
                this.tourom === '99' ||
                  this.tourom === '98' ||
                  this.tourom === '0' ||
                  this.tourom === 'T1'
              "
            ></div>
            <div v-else>
              <b-dropdown v-if="stats.ooms === ''" :text="'Report'">
                <b-dropdown-item>No Stats</b-dropdown-item>
              </b-dropdown>
              <b-dropdown
                v-else-if="Array.isArray(stats.ooms.ooms_entry)"
                :text="data.short_name"
              >
                <b-dropdown-item
                  v-for="(ooms, index) in stats.ooms.ooms_entry"
                  :key="index + Math.random()"
                  v-on:click="pickStat"
                  :title="'ooms-oom-' + ooms.code"
                  >{{ ooms.short_name }}</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown v-else :text="data.short_name">
                <b-dropdown-item
                  v-on:click="pickStat"
                  :title="'ooms-oom-' + stats.ooms.ooms_entry.code"
                  >{{ stats.ooms.ooms_entry.full_name }}</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </template>
        </b-container>
      </div>
    </template>
    <template v-else>
      <div class="titleLine">
        <b-container class="topSec">
          <template >
            <b-row>
              <b-col>
                <div class="titleBackGround">
                  <b-row class="topSecrow">
                    <b-col md='6' cols='12'>
                      <h2 class="fullName">
                        {{ data.full_name }} 
                      </h2>
                    </b-col>
                    <b-col md="6" cols="12">
                        <b-nav pills>
                            <div>
                            <b-dropdown v-if="stats.ooms === ''" :text="'Report'">
                                <b-dropdown-item>No Stats</b-dropdown-item>
                            </b-dropdown>
                            <b-dropdown
                                v-else-if="Array.isArray(stats.ooms.ooms_entry)"
                                :text="data.short_name"
                            >
                                <b-dropdown-item
                                v-for="(ooms, index) in stats.ooms.ooms_entry"
                                :key="index + Math.random()"
                                v-on:click="pickStat"
                                :title="'ooms-oom-' + ooms.code"
                                >{{ ooms.short_name }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                v-on:click="pickStat"
                                v-for="(stats, index) in stats.stats.stats_entry"
                                :key="index + Math.random()"
                                :title="'stats-stats-' + stats.code"
                                >{{ stats.full_name }}</b-dropdown-item
                                >
                            </b-dropdown>
                            <b-dropdown v-else :text="data.short_name">
                                <b-dropdown-item
                                v-on:click="pickStat"
                                :title="'ooms-oom-' + stats.ooms.ooms_entry.code"
                                >{{ stats.ooms.ooms_entry.full_name }}</b-dropdown-item
                                >
                            </b-dropdown>
                            </div>
                        </b-nav>
                    </b-col>
                  </b-row>
                </div>
                <!-- <b-row class="topSecrow">
                  <b-col>
                    <span class='rookie'><b-img class='tag' :src="config.VUE_APP_ASSETS+'2020/04/Theauer.png'"></b-img> - eligible for TAG Heuer Rookie of the Year</span>
                  </b-col>
                </b-row> -->
              </b-col>
            </b-row>
          </template>
        </b-container>
      </div>
    </template>
    <b-container>
      <b-row>
        <b-col>
          <h3 class='noStats' v-if="stats.ooms === ''">
            No Stats For This Season
          </h3>
          <StatsTable v-else :id="id" :stat="stat" :data="data" :config="config"/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import StatsTable from "@/components/statsTable.vue";
import MiniMenu from "@/components/miniMenu.vue";
export default {
  name: "oom",
  components: {
    StatsTable,
    MiniMenu
  },
  props:['config'],
  data() {
    return {
      stats: [],
      id: this.$route.query.id,
      stat: [],
      data: [],
      tm_params: [],
      tourom: process.env.VUE_APP_OOM,
      tour: process.env.VUE_APP_TOUR,
      fullList: [],
    };
  },
  methods: {
    isMobile() {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    },
    tourOm: function(oom) {
      if (this.$route.query.tour === "aus") oom = "99";
      else if (this.$route.query.tour === "snr") oom = "98";
      else if (this.$route.query.tour === "all") oom = "N1";
      else if (this.$route.query.tour === "trn") oom = "81";
      else oom = "0";
      return oom;
    },
    changeYear: function(event) {
      var id = event.target.getAttribute("title");
      return (
        (this.id = id),
        axios
          .get(
            "https://pga.org.au/api/pgaaus/cache/" +
              this.$route.query.tour +
              "/" +
              this.id +
              "/tmticx?field=Y&fields=ooms&fields=stats&randomadd=" +
              new Date().getTime()
          )
          .then((response) => {
            this.stats = response.data;
            return axios.get(
              "https://pga.org.au/api/pgaaus/cache/" +
                this.$route.query.tour +
                "/" +
                this.id +
                "/" +
                this.id +
                "-ooms-oom-" +
                this.tourom +
                ".json?randomadd=" +
                new Date().getTime()
            );
          })
          .then((response) => {
            this.data = response.data;
          })
      );
    },
    pickStat: function(event) {
      var stat = event.target.getAttribute("title");
      return (
        (this.stat = stat),
        axios
          .get(
            "https://pga.org.au/api/pgaaus/cache/" +
              this.$route.query.tour +
              "/" +
              this.id +
              "/" +
              this.id +
              "-" +
              this.stat +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then((response) => (this.data = response.data))
      );
    },
  },
  mounted() {
    axios //add API Call
      .get(
        "https://pga.org.au/api/pgaaus/cache/" +
          this.$route.query.tour +
          "/tmticx?randomadd=" +
          new Date().getTime()
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.tm_params = response.data;
        return axios.get(
          "https://pga.org.au/api/pgaaus/cache/" +
            this.$route.query.tour +
            "/" +
            this.$route.query.id +
            "/tmticx?field=Y&fields=ooms&fields=stats&randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.stats = response.data;
        this.statlist = response.data.stats.stats_entry;
        this.oomlist = response.data.ooms.ooms_entry;
        this.fullList = this.oomlist.concat(this.statlist);
        
        return axios.get(
          "https://pga.org.au/api/pgaaus/cache/" +
            this.$route.query.tour +
            "/" +
            this.id +
            "/" +
            this.id +
            "-ooms-oom-" +
            this.tourom +
            ".json?randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.data = response.data;
      });
  },
};
</script>

<style scoped>
.tRowTopMainnonMobile {
  background-color: transparent!important;
}
.tRowTopMain {
  background-color: rgba(0, 0, 0, 0.04);
}
::v-deep .dropdown-item {
  color: #263056!important;
}
.fullName {
  font-weight: 600;
  font-family: 'Gantari', sans-serif !important;
  color: #000;
  font-size: 50px;
  position: relative;
  top: -10px;
}
.titleBackGround:before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  background-color: #fff;
  z-index: 2;
  height: 100%;
  width: 25px;
}
.titleBackGround{
  text-align: left;
  width: auto;
  /* display: inline-block; */
  background-color: #fff;
  z-index: 2;
  position: relative;
  padding-right: 25px;
  position: relative;
  padding-left: 2px;
}
.titleLine:before {
  content: "";
  display: block;
  height: 5px;
  width: 100%;
  background-color: #30e0bb;
  position: absolute;
  position: relative;
  top: 81px;
}
::v-deep table > thead > tr > th {
  padding: 0.75rem;
}
::v-deep .table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.tRow {
  border-bottom: 0px solid #000;
  padding: 2px 0;
  background: rgba(0, 0, 0, 0.04);
}
.nav {
  float: right;
}
::v-deep button {
  color: black;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  width: 250px;
  text-align: left;
}
::v-deep button::after {
  float: right;
  margin-top: 10px;
}
.noStats{
  text-align: center;
}
::v-deep button:hover {
  color: #003C52;
  background-color: white;
}
#oomPage {
  /* background-color: #f9f9f9; */
  padding-bottom: 120px;
}
.topSec {
  padding-top: 2%;
  padding-bottom: 2%;
}
.topSecrow {
  padding-top: 2%;
}
.tag{
  width: 30px;
}
.rookie{
  padding-left: 15px;
  padding-top: 15px;
  font-size: 11pt;
  font-family: 'Gantari', sans-serif!important;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.1;
}
.ad {
  padding: 15px 0;
}
@media only screen and (max-width: 768px) {
  ::v-deep .btn-group, .btn-group-vertical {
    display: block!important;
  }
  ::v-deep button {
    color: black;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0;
    width: 100%!important;
    text-align: left;
  }
  .fullName {
    font-weight: 600;
    font-family: 'Gantari', sans-serif !important;
    color: #000;
    font-size: 33px;
    position: relative;
    top: -10px;
  }
  .titleLine[data-v-0aef1de3]:before {
    content: "";
    display: block;
    height: 5px;
    width: 100%;
    background-color: #30e0bb;
    position: absolute;
    position: relative;
    top: 50px;
  }
}
@media only screen and (max-width: 480px) {
  .nav{
    float: left;
  }
}
</style>
