<template>
  <div>
    <div
    >

      <!-- Find a Pro -->

        <template v-if="$route.name === 'findPro'" >
            <div class="sched-ad op"> 
                <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="application/javascript"></script>
                <script type="application/javascript">
                    window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() 
                    {   
                        billboardsizes = googletag.sizeMapping() .addSize([980,690],[728,90]) .addSize([0,0],[320,50]) 
                        .build(); googletag.defineSlot('/2835490/ProAmSeriesMiddle', [[970, 250], [320, 50], [728, 90]], 
                        'div-gpt-ad-1568956528388-0').defineSizeMapping(billboardsizes).addService(googletag.pubads()); 
                        googletag.pubads().enableSingleRequest(); googletag.enableServices(); 
                    }); 
                </script> 
                <div id='div-gpt-ad-1568956528388-0'> 
                    <script type="application/javascript"> 
                        googletag.cmd.push(function() { 
                            googletag.display('div-gpt-ad-1568956528388-0'); 
                        }); 
                    </script> 
                </div> 
            </div>
        </template>

      <!-- Reports Page -->

        <template v-if="$route.name === 'reports' || $route.name === 'fullreports'" >
            <div class="sched-ad op"> 
                <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="application/javascript"></script>
                <script type="application/javascript">
                    window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() 
                    {   
                        billboardsizes = googletag.sizeMapping() .addSize([980,690],[728,90]) .addSize([0,0],[320,50]) 
                        .build(); googletag.defineSlot('/2835490/ProAmSeriesMiddle', [[970, 250], [320, 50], [728, 90]], 
                        'div-gpt-ad-1568956528388-0').defineSizeMapping(billboardsizes).addService(googletag.pubads()); 
                        googletag.pubads().enableSingleRequest(); googletag.enableServices(); 
                    }); 
                </script> 
                <div id='div-gpt-ad-1568956528388-0'> 
                    <script type="application/javascript"> 
                        googletag.cmd.push(function() { 
                            googletag.display('div-gpt-ad-1568956528388-0'); 
                        }); 
                    </script> 
                </div> 
            </div>
        </template>
      <!-- All Schedule Desktop Banners -->

      <!-- Schedule Tour Aus-->

        <template v-if="tour === 'aus'" >
            <div class="sched-ad"> 
                <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="application/javascript"></script>
                <script type="application/javascript">
                    window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() 
                    {   
                        billboardsizes = googletag.sizeMapping() .addSize([980,690],[728,90]) .addSize([0,0],[320,50]) 
                        .build(); googletag.defineSlot('/2835490/ProAmSeriesMiddle', [[970, 250], [320, 50], [728, 90]], 
                        'div-gpt-ad-1568956528388-0').defineSizeMapping(billboardsizes).addService(googletag.pubads()); 
                        googletag.pubads().enableSingleRequest(); googletag.enableServices(); 
                    }); 
                </script> 
                <div id='div-gpt-ad-1568956528388-0'> 
                    <script type="application/javascript"> 
                        googletag.cmd.push(function() { 
                            googletag.display('div-gpt-ad-1568956528388-0'); 
                        }); 
                    </script> 
                </div> 
            </div>
        </template>

      <!-- Schedule Tour MPP-->

        <template v-if="tour === 'mpp'">
            <div class="sched-ad"> 
                <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="application/javascript"></script>
                <script type="application/javascript">
                    window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() 
                    {   
                        billboardsizes = googletag.sizeMapping() .addSize([980,690],[728,90]) .addSize([0,0],[320,50]) 
                        .build(); googletag.defineSlot('/2835490/ProAmSeriesMiddle', [[970, 250], [320, 50], [728, 90]], 
                        'div-gpt-ad-1568956528388-0').defineSizeMapping(billboardsizes).addService(googletag.pubads()); 
                        googletag.pubads().enableSingleRequest(); googletag.enableServices(); 
                    }); 
                </script> 
                <div id='div-gpt-ad-1568956528388-0'> 
                    <script type="application/javascript"> 
                        googletag.cmd.push(function() { 
                            googletag.display('div-gpt-ad-1568956528388-0'); 
                        }); 
                    </script> 
                </div> 
            </div>
        </template>

      <!-- Schedule Tour TRN-->

        <template v-if="tour === 'trn'">
            <div class="sched-ad"> 
                <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="application/javascript"></script>
                <script type="application/javascript">
                    window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() 
                    {   
                        billboardsizes = googletag.sizeMapping() .addSize([980,690],[728,90]) .addSize([0,0],[320,50]) 
                        .build(); googletag.defineSlot('/2835490/ProAmSeriesMiddle', [[970, 250], [320, 50], [728, 90]], 
                        'div-gpt-ad-1568956528388-0').defineSizeMapping(billboardsizes).addService(googletag.pubads()); 
                        googletag.pubads().enableSingleRequest(); googletag.enableServices(); 
                    }); 
                </script> 
                <div id='div-gpt-ad-1568956528388-0'> 
                    <script type="application/javascript"> 
                        googletag.cmd.push(function() { 
                            googletag.display('div-gpt-ad-1568956528388-0'); 
                        }); 
                    </script> 
                </div> 
            </div>
        </template>

      <!-- Schedule Tour SNR-->

        <template v-if="tour === 'snr'">
            <div class="sched-ad"> 
                <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="application/javascript"></script>
                <script type="application/javascript">
                    window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() 
                    {   
                        billboardsizes = googletag.sizeMapping() .addSize([980,690],[728,90]) .addSize([0,0],[320,50]) 
                        .build(); googletag.defineSlot('/2835490/ProAmSeriesMiddle', [[970, 250], [320, 50], [728, 90]], 
                        'div-gpt-ad-1568956528388-0').defineSizeMapping(billboardsizes).addService(googletag.pubads()); 
                        googletag.pubads().enableSingleRequest(); googletag.enableServices(); 
                    }); 
                </script> 
                <div id='div-gpt-ad-1568956528388-0'> 
                    <script type="application/javascript"> 
                        googletag.cmd.push(function() { 
                            googletag.display('div-gpt-ad-1568956528388-0'); 
                        }); 
                    </script> 
                </div> 
            </div>
        </template>

      <!-- Schedule Tour PPC-->

        <template v-if="tour === 'ppc'">
            <div class="sched-ad"> 
                <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="application/javascript"></script>
                <script type="application/javascript">
                    window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() 
                    {   
                        billboardsizes = googletag.sizeMapping() .addSize([980,690],[728,90]) .addSize([0,0],[320,50]) 
                        .build(); googletag.defineSlot('/2835490/ProAmSeriesMiddle', [[970, 250], [320, 50], [728, 90]], 
                        'div-gpt-ad-1568956528388-0').defineSizeMapping(billboardsizes).addService(googletag.pubads()); 
                        googletag.pubads().enableSingleRequest(); googletag.enableServices(); 
                    }); 
                </script> 
                <div id='div-gpt-ad-1568956528388-0'> 
                    <script type="application/javascript"> 
                        googletag.cmd.push(function() { 
                            googletag.display('div-gpt-ad-1568956528388-0'); 
                        }); 
                    </script> 
                </div> 
            </div>
        </template>

      <!-- Schedule Tour ALL-->

        <template v-if="tour === 'all'">
            <div class="sched-ad"> 
                <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="application/javascript"></script>
                <script type="application/javascript">
                    window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() 
                    {   
                        billboardsizes = googletag.sizeMapping() .addSize([980,690],[728,90]) .addSize([0,0],[320,50]) 
                        .build(); googletag.defineSlot('/2835490/ProAmSeriesMiddle', [[970, 250], [320, 50], [728, 90]], 
                        'div-gpt-ad-1568956528388-0').defineSizeMapping(billboardsizes).addService(googletag.pubads()); 
                        googletag.pubads().enableSingleRequest(); googletag.enableServices(); 
                    }); 
                </script> 
                <div id='div-gpt-ad-1568956528388-0'> 
                    <script type="application/javascript"> 
                        googletag.cmd.push(function() { 
                            googletag.display('div-gpt-ad-1568956528388-0'); 
                        }); 
                    </script> 
                </div> 
            </div>
        </template>
  </div>
  </div>
</template>

<script>
export default {
  name: "ads",
  props: ['page']
};
</script>

<style scoped>
.sched-ad {
    text-align: center;
    padding-bottom: 1em;
}
</style>
