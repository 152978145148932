<template>
  <div class="tour" id="tourTop">
    <div class="tRowTopMain" :class="{tRowTopMainnonMobile : isMobile(true) }">
      <MiniMenu
        :config="config"
        :seasonCode="id"
        :tour="tour"
        :tournamentName="tourinfo.full_name"
      />
    </div>
    <div :style="backgroundColor" class="showMobile" id="tourTop">
        <b-row>
          <b-col v-if="tourinfo.course_images === 'Y'" lg="8">
            <b-img
              :class="[splash]"
              :src="
                'https://ocs-aus.sgp1.cdn.digitaloceanspaces.com/pgaaus/courses/' +
                  this.id +
                  '/' +
                  tourinfo.course_code +
                  '/' +
                  tourinfo.course_image_file_1
              "
            >
            </b-img> 
          </b-col>
          <b-col v-else lg="8">
            <b-img
              src="https://pga.org.au/wp-content/themes/ocs-quantum/img/course.png"
              :class="[splash]"
            >
            </b-img>
          </b-col>
          <b-col lg="4">
            <b-container>
              <div class="leftsideText">
                <h2 class="fullName">
                  {{ tourinfo.full_name }}
                </h2>
                <h4 class="courseName">
                  {{ tourinfo.course_name }}
                </h4>
                <p class="courseName">
                  {{ tourinfo.dates }}
                </p>
              </div>
            </b-container>
          </b-col>
        </b-row>
    </div>
    <div :style="backgroundColor" class="showDesktop" id="tourTop">
      <b-container>
        <b-row>
          <b-col lg="4">
            <div class="leftsideText">
              <h2 class="fullName">
                {{ tourinfo.full_name }}
              </h2>
              <h4 class="courseName">
                {{ tourinfo.course_name }}
              </h4>
              <p class="courseName">
                {{ tourinfo.dates }}
              </p>
            </div>
          </b-col>
          <b-col v-if="tourinfo.course_images === 'Y'" lg="8">
            <b-img
              :class="[splash]"
              :src="
                'https://ocs-aus.sgp1.cdn.digitaloceanspaces.com/pgaaus/courses/' +
                  this.id +
                  '/' +
                  tourinfo.course_code +
                  '/' +
                  tourinfo.course_image_file_1
              "
            >
            </b-img> 
          </b-col>
          <b-col v-else lg="8">
            <b-img
              src="https://pga.org.au/wp-content/themes/ocs-quantum/img/course.png"
              :class="[splash]"
            >
            </b-img>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container>
      <div class="MainInfo">
        <template>
          <b-row class="rowPad showMobSec">
            <b-col cols="12">
              <b-row>
                <b-col>
                  <h2>
                    <span class="courseInfo">Prize Fund</span>
                  </h2>
                  <h3 class="valuesize">
                    {{ tourinfo.total_prize_fund }}
                  </h3>
                </b-col>
                <b-col>
                  <h2>
                    <span class="courseInfo">Venue</span>
                  </h2>
                  <h3 class="valuesize">
                    {{ tourinfo.course_name }}
                  </h3>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <b-row>
                <b-col>
                  <h2>
                    <span class="courseInfo">Rounds:</span>
                  </h2>
                  <h3 class="valuesize">
                    {{ tourinfo.rounds }}
                  </h3>
                </b-col>
                <b-col>
                  <h2>
                    <span class="courseInfo">Par</span>
                  </h2>
                  <h3 class="valuesize">
                    {{ tourinfo.course_total_par }}
                  </h3>
                </b-col>
              </b-row>
                <div>
                  <div class="reportsListMob" >
                    <span v-if="tourinfo.allow_nonm_entry == 'Y'">
                      <b-link href="https://members.pga.org.au/MyPGA/nmt_home.aspx" target="_blank">
                        <b-button style="margin-bottom:3px" :style="backgroundColor" class='enter'>Non-member Entry</b-button>
                      </b-link>
                    </span>
                    <span v-if="tourinfo.allow_nonm_entry == 'N'">
                      <b-button style="margin-bottom:3px" disabled class='enter disableEntry'>Non-member Entry</b-button>
                    </span>
                    <span v-if="until > 14">
                      <b-link href="https://members.pga.org.au/MyPGA/Default.aspx?WebsiteKey=1f56e013-54b9-4659-a93b-a1a1591612a3&LoginRedirect=true&returnurl=%2fMyPGA%2fmy_tournaments.aspx" target="_blank">
                        <b-button :style="backgroundColor" class='enter'>Enter</b-button>
                      </b-link>
                    </span>
                    <div v-if="fullReports !== ''">
                      <b-nav-item
                        class="btnColor"
                        v-if="filterEntry.length && filterEntry !== 'none'"
                        href="#"
                        role="presentation"
                        :to="{
                          name: 'reports',
                          query: {
                            url: filterEntry.slice(-1)[0].report_url,
                            id: id,
                            code: code,
                            tour:tour,
                            title: filterEntry.slice(-1)[0].report_title
                          }
                        }"
                        >
                          Entries <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                      </b-nav-item>
                      <b-nav-item
                        class="btnColor"
                        v-else
                        role="presentation"
                        :to="{
                          name: 'reports-page',
                          query: {
                            url: tourinfo.reports.reports_entry.report_url,
                            id: id,
                            code: code,
                            tour: tour,
                            title: tourinfo.reports.reports_entry.report_title
                          }
                        }"
                        >
                          Entries <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                      </b-nav-item>
                      <b-nav-item
                        class="btnColor"
                        v-if="filterDraw.length && filterDraw !== 'none'"
                        href="#"
                        role="presentation"
                        :to="{
                          name: 'reports',
                          query: {
                            url: filterDraw.slice(-1)[0].report_url,
                            id: id,
                            code: code,
                            tour:tour,
                            title: filterDraw.slice(-1)[0].report_title
                          }
                        }"
                        >
                          Draw <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                      </b-nav-item>
                      <b-nav-item
                        class="btnColor"
                        v-if="filterScore.length && filterScore !== 'none'"
                        role="presentation"
                        :to="{
                          name: 'reports',
                          query: {
                            url: filterScore.slice(-1)[0].report_url,
                            id: id,
                            code: code,
                            tour:tour,
                            title: filterScore.slice(-1)[0].report_title
                          }
                        }"
                        >
                          Scores <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                      </b-nav-item>
                      <b-nav-item
                        class="btnColor"
                        v-if="filterFinal.length && filterFinal !== 'none'"
                        role="presentation"
                        :to="{
                          name: 'reports',
                          query: {
                            url: filterFinal.slice(-1)[0].report_url,
                            id: id,
                            code: code,
                            tour:tour,
                            title: filterFinal.slice(-1)[0].report_title
                          }
                        }"
                        >
                          Results  <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                      </b-nav-item>
                      <b-nav-item
                        class="btnColor"
                        v-if="tourinfo.scoring_url !==''"
                        :href="tourinfo.scoring_url"
                        target="_blank"
                        >External Live Scoring
                      </b-nav-item>
                    </div>
                  </div>
                  <b-img :class="[logo]" :src="tourinfo.sponsor_logo"></b-img>
                </div>
            </b-col>
          </b-row>
        </template>
        <template>
          <b-row class="rowPad showDeskSec">
            <b-col cols="6">
              <b-row>
                <b-col>
                  <h2>
                    <span class="courseInfo">Prize Fund</span>
                  </h2>
                  <h3 class="valuesize">
                    {{ tourinfo.total_prize_fund }}
                  </h3>
                  <br>
                  <h2>
                    <span class="courseInfo">Venue</span>
                  </h2>
                  <h3 class="valuesize">
                    {{ tourinfo.course_name }}
                  </h3>
                </b-col>
                <b-col class="parRight">
                  <h2>
                    <span class="courseInfo">Par</span>
                  </h2>
                  <h3 class="valuesize">
                    {{ tourinfo.course_total_par }}
                  </h3>
                  <br>
                  <h2>
                    <span class="courseInfo">Rounds:</span>
                  </h2>
                  <h3 class="valuesize">
                    {{ tourinfo.rounds }}
                  </h3>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col>
                  <b-img class="sponsorDesk" :src="tourinfo.sponsor_logo"></b-img>
                </b-col>
                <b-col>
                  <div>
                    <span v-if="tourinfo.allow_nonm_entry == 'Y'">
                      <b-link href="https://members.pga.org.au/MyPGA/nmt_home.aspx" target="_blank">
                        <b-button style="margin-bottom: 2px;" :style="backgroundColor" class='enter'>Non-member Entry <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" /></b-button>
                      </b-link>
                    </span>
                    <span v-if="tourinfo.allow_nonm_entry == 'N'">
                      <b-button style="margin-bottom: 2px;" disabled class='enter disableEntry'>Non-member Entry <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" /></b-button>
                    </span>
                    <span v-if="until > 14">
                      <b-link href="https://members.pga.org.au/MyPGA/Default.aspx?WebsiteKey=1f56e013-54b9-4659-a93b-a1a1591612a3&LoginRedirect=true&returnurl=%2fMyPGA%2fmy_tournaments.aspx" target="_blank">
                        <b-button :style="backgroundColor" class='enter'>Enter <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" /></b-button>
                      </b-link>
                    </span>
                    <div v-if="fullReports !== ''">
                      <b-nav-item
                        class="btnColor"
                        v-if="filterEntry.length && filterEntry !== 'none'"
                        href="#"
                        role="presentation"
                        :to="{
                          name: 'reports',
                          query: {
                            url: filterEntry.slice(-1)[0].report_url,
                            id: id,
                            code: code,
                            tour:tour,
                            title: filterEntry.slice(-1)[0].report_title
                          }
                        }"
                        >
                          Entries <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                      </b-nav-item>
                      <b-nav-item
                        class="btnColor"
                        v-else
                        role="presentation"
                        :to="{
                          name: 'reports-page',
                          query: {
                            url: tourinfo.reports.reports_entry.report_url,
                            id: id,
                            code: code,
                            tour: tour,
                            title: tourinfo.reports.reports_entry.report_title
                          }
                        }"
                        >
                          Entries <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                      </b-nav-item>
                      <b-nav-item
                        class="btnColor"
                        v-if="filterDraw.length && filterDraw !== 'none'"
                        href="#"
                        role="presentation"
                        :to="{
                          name: 'reports',
                          query: {
                            url: filterDraw.slice(-1)[0].report_url,
                            id: id,
                            code: code,
                            tour:tour,
                            title: filterDraw.slice(-1)[0].report_title
                          }
                        }"
                        >
                          Draw <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                      </b-nav-item>
                      <b-nav-item
                        class="btnColor"
                        v-if="filterScore.length && filterScore !== 'none'"
                        role="presentation"
                        :to="{
                          name: 'reports',
                          query: {
                            url: filterScore.slice(-1)[0].report_url,
                            id: id,
                            code: code,
                            tour:tour,
                            title: filterScore.slice(-1)[0].report_title
                          }
                        }"
                        >
                          Scores <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                      </b-nav-item>
                      <b-nav-item
                        class="btnColor"
                        v-if="filterFinal.length && filterFinal !== 'none'"
                        role="presentation"
                        :to="{
                          name: 'reports',
                          query: {
                            url: filterFinal.slice(-1)[0].report_url,
                            id: id,
                            code: code,
                            tour:tour,
                            title: filterFinal.slice(-1)[0].report_title
                          }
                        }"
                        >
                          Results  <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                      </b-nav-item>
                      <b-nav-item
                        class="btnColor"
                        v-if="tourinfo.scoring_url !==''"
                        :href="tourinfo.scoring_url"
                        target="_blank"
                        >External Live Scoring
                      </b-nav-item>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </div>
      <div class="overflowPad" v-if="tourinfo.tourn_desc_found == 'Y'">
        <b-row>
          <b-col>
            <div class="holebyHole">
              <h2>
                <span class="courseInfo">Overview</span>
              </h2>
            </div>
            <p v-html="tourinfo.tourn_desc" class="tournDesc"></p>
            <!-- <OverView :tournCode='this.$route.query.code'/> -->
          </b-col>
        </b-row>
      </div>
      <div class="hbhSpace" v-if="tourinfo.match_play == 'N'">
        <div class="holebyHole">
          <h2>
            <span class="courseInfo">Hole By Hole</span>
          </h2>
        </div>
        <template v-if="tourinfo.multi_course === 'N'">
        <b-row :class="courseStats">
          <b-col>
            <table class="courseinfo">
              <thead>
                <tr :style="backgroundColor">
                  <th>
                    Hole
                  </th>
                  <th>
                    1
                  </th>
                  <th>
                    2
                  </th>
                  <th>
                    3
                  </th>
                  <th>
                    4
                  </th>
                  <th>
                    5
                  </th>
                  <th>
                    6
                  </th>
                  <th>
                    7
                  </th>
                  <th>
                    8
                  </th>
                  <th>
                    9
                  </th>
                  <th>
                    out
                  </th>
                  <th>
                    10
                  </th>
                  <th>
                    11
                  </th>
                  <th>
                    12
                  </th>
                  <th>
                    13
                  </th>
                  <th>
                    14
                  </th>
                  <th>
                    15
                  </th>
                  <th>
                    16
                  </th>
                  <th>
                    17
                  </th>
                  <th>
                    18
                  </th>
                  <th>
                    In
                  </th>
                  <th>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Par
                  </td>
                  <td
                    v-for="(number, index) in tourinfo.course_par.split(',').slice(0, 9)"
                    :key="index"
                  >
                    {{ number }}
                  </td>
                  <td>
                    {{ tourinfo.course_out_par.slice(1, 5) }}
                  </td>
                  <td
                    v-for="(number, index) in tourinfo.course_par
                      .split(',')
                      .slice(9, 18)"
                    :key="index"
                  >
                    {{ number }}
                  </td>
                  <td>
                    {{ tourinfo.course_in_par.slice(1, 5) }}
                  </td>
                  <td>
                    {{ tourinfo.course_total_par }}
                  </td>
                </tr>
                <tr>
                  <td :style="backgroundColor">
                    Yards
                  </td>
                  <td :style="backgroundColor"
                    v-for="(number, index) in tourinfo.course_length_yards
                      .split(',')
                      .slice(0, 9)"
                    :key="index"
                  >
                    {{ number }}
                  </td>
                  <td :style="backgroundColor">
                    {{ outVal(tourinfo.course_length_yards) }}
                  </td>
                  <td :style="backgroundColor"
                    v-for="(number,index) in tourinfo.course_length_yards
                      .split(',')
                      .slice(9, 18)"
                    :key="index"
                  >
                    {{ number }}
                  </td>
                  <td :style="backgroundColor">
                    {{ inVal(tourinfo.course_length_yards) }}
                  </td>
                  <td :style="backgroundColor">
                    {{ totalVal(tourinfo.course_length_yards) }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Meters
                  </td>
                  <td
                    v-for="(number, index) in tourinfo.course_length_meters
                      .split(',')
                      .slice(0, 9)"
                    :key="index"
                  >
                    {{ number }}
                  </td>
                  <td>
                    {{ outVal(tourinfo.course_length_meters) }}
                  </td>
                  <td
                    v-for="(number, index) in tourinfo.course_length_meters
                      .split(',')
                      .slice(9, 18)"
                    :key="index"
                  >
                    {{ number }}
                  </td>
                  <td>
                    {{ inVal(tourinfo.course_length_meters) }}
                  </td>
                  <td>
                    {{ totalVal(tourinfo.course_length_meters) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        </template>
        <template v-if="tourinfo.multi_course === 'Y'">
         <div v-for="(multi, index) in tourinfo.courses.courses_entry" :key='index'>
           <h2 style="text-align:center;">
             {{multi.course_X_id}}
           </h2>
        <b-row :class="courseStats">
          <b-col>
            <table class="courseinfo">
              <thead>
                <tr class="title" :style="backgroundColor">
                  <th>
                    Hole
                  </th>
                  <th>
                    1
                  </th>
                  <th>
                    2
                  </th>
                  <th>
                    3
                  </th>
                  <th>
                    4
                  </th>
                  <th>
                    5
                  </th>
                  <th>
                    6
                  </th>
                  <th>
                    7
                  </th>
                  <th>
                    8
                  </th>
                  <th>
                    9
                  </th>
                  <th>
                    out
                  </th>
                  <th>
                    10
                  </th>
                  <th>
                    11
                  </th>
                  <th>
                    12
                  </th>
                  <th>
                    13
                  </th>
                  <th>
                    14
                  </th>
                  <th>
                    15
                  </th>
                  <th>
                    16
                  </th>
                  <th>
                    17
                  </th>
                  <th>
                    18
                  </th>
                  <th>
                    In
                  </th>
                  <th>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Par
                  </td>
                  <td
                    v-for="(number, index) in multi.course_X_par.split(',').slice(0, 9)"
                    :key="index"
                  >
                    {{ number }}
                  </td>
                  <td>
                    {{ multi.course_X_out_par.slice(1, 5) }}
                  </td>
                  <td
                    v-for="(number, index) in multi.course_X_par
                      .split(',')
                      .slice(9, 18)"
                    :key="index"
                  >
                    {{ number }}
                  </td>
                  <td>
                    {{ multi.course_X_in_par.slice(1, 5) }}
                  </td>
                  <td>
                    {{ multi.course_X_total_par }}
                  </td>
                </tr>
                <tr>
                  <td class='title' :style="backgroundColor">
                    Yards
                  </td>
                  <td class='title' :style="backgroundColor"
                    v-for="(number, index) in multi.course_X_length_yards
                      .split(',')
                      .slice(0, 9)"
                    :key="index"
                  >
                    {{ number }}
                  </td>
                  <td class='title' :style="backgroundColor">
                    {{ outVal(multi.course_X_length_yards) }}
                  </td>
                  <td class='title' :style="backgroundColor"
                    v-for="(number,index) in multi.course_X_length_yards
                      .split(',')
                      .slice(9, 18)"
                    :key="index"
                  >
                    {{ number }}
                  </td>
                  <td class='title' :style="backgroundColor">
                    {{ inVal(multi.course_X_length_yards) }}
                  </td>
                  <td class='title' :style="backgroundColor">
                    {{ totalVal(multi.course_X_length_yards) }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Meters
                  </td>
                  <td
                    v-for="(number, index) in multi.course_X_length_meters
                      .split(',')
                      .slice(0, 9)"
                    :key="index"
                  >
                    {{ number }}
                  </td>
                  <td>
                    {{ outVal(multi.course_X_length_meters) }}
                  </td>
                  <td
                    v-for="(number, index) in multi.course_X_length_meters
                      .split(',')
                      .slice(9, 18)"
                    :key="index"
                  >
                    {{ number }}
                  </td>
                  <td>
                    {{ inVal(multi.course_X_length_meters)}}
                  </td>
                  <td>
                    {{ totalVal(multi.course_X_length_meters) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
         </div>
        </template>
      </div>
    </b-container>

    <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="application/javascript"></script>
    <script type="application/javascript">
      window.googletag = window.googletag || {cmd: []};
      googletag.cmd.push(function() {
      billboardsizes = googletag.sizeMapping()
      .addSize([980,690],[728,90])
      .addSize([0,0],[320,50])
      .build();
      googletag.defineSlot('/2835490/New_PGA_Tier2_Banner', [[970, 250], [728, 90],  [320, 50],], 'div-gpt-ad-1568956460842-0').defineSizeMapping(billboardsizes).addService(googletag.pubads());
      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
      });
    </script>

    <div id='div-gpt-ad-1568956460842-0' class="lazy" style="text-align:center">
      <div>
        <script type="application/javascript">
          googletag.cmd.push(function() { googletag.display('div-gpt-ad-1568956460842-0'); });
        </script>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MiniMenu from "@/components/miniMenu.vue";
// import OverView from "@/components/overView.vue";

export default {
  name: "Tournament",
  components: {
    MiniMenu,
  },
  props:['config'],
  data() {
    return {
      tourinfo: [],
      nameTourn: '',
      splash: "splash",
      splashText: "splashText",
      logo: "logo",
      overlay: "overlay",
      courseStats: "courseStats",
      until: [],
      tour: process.env.VUE_APP_TOUR,
      id:this.$route.query.id,
      code:this.$route.query.code,
      reports: [],
      backgroundColor: {
        backgroundColor: process.env.VUE_APP_REPORT_MAIN_COLOUR,
        color: '#fff'
      },
      fullReports: []
    };
  },
  methods:{
    // autoScroll:function(){
    //   console.log('IN')
      // const element = document.getElementById("tourTop");
      // element.scrollIntoView();
      // document.getElementById("tourTop").onload = function () { this.contentWindow.scrollTo(0, 200) };

    // },
    isMobile() {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    },
    proAm: function(){
      if (this.$route.query.tour === 'all')
      return this.tourinfo.class_group.toLowerCase()
      else
      return this.$route.query.tour
    },
    outVal(value) {
      var split = value.split(",");
      var front9 = split.slice(0, 9);
      for (var i = 0; i < front9.length; i++) front9[i] = +front9[i];
      var sum = front9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
   inVal(value) {
      var split = value.split(",");
      var back9 = split.slice(9, 18);
      for (var i = 0; i < back9.length; i++) back9[i] = +back9[i];
      var sum = back9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
    totalVal(value) {
      var split = value.split(",");
      for (var i = 0; i < split.length; i++) split[i] = +split[i];
      var sum = split.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
  },
  mounted() {
    axios //add API Call
      .get(
        process.env.VUE_APP_TIC_BASE +
          this.$route.query.tour +
          "/" +
          this.$route.query.id +
          "/" +
          this.$route.query.id +
          "-" +
          this.$route.query.code +
          "-tmticx.json?randomadd=" +
          new Date().getTime()
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.tourinfo = response.data;
        this.nameTourn = response.data.full_name;
        // console.log("this.nameTourn")
        // console.log(this.nameTourn)
        localStorage.setItem("Tournament Name", this.nameTourn);
        localStorage.setItem("Tournament Link", this.$route.fullPath);
        this.until = response.data.days_away;
        console.log("this.until")
        console.log(this.until)
        this.fullReports = response.data.reports;
        if (Array.isArray(response.data.reports.reports_entry)) {
          console.log("isArray");
          this.reports = response.data.reports.reports_entry;
        } else {
          console.log("is not Array");
          this.reports = [response.data.reports.reports_entry];
        }
      })
  },
  computed: {
    rPlayed: function() {
      return this.tourinfo.rounds_played;
    },
    filterEntry: function(entry) {
      if (Array.isArray(this.tourinfo.reports.reports_entry))
        entry = this.tourinfo.reports.reports_entry.filter(
          tourinfo => !tourinfo.report_title.indexOf("Tour")
        );
      else if (this.tourinfo.reports.length)
        entry = this.tourinfo.reports.reports_entry.report_title;
      else entry = "none";
      return entry;
    },
    filterDraw: function(draw) {
      if (Array.isArray(this.tourinfo.reports.reports_entry))
        if (this.rPlayed == "0") {
          draw = this.tourinfo.reports.reports_entry.filter(
            tourinfo => !tourinfo.report_title.indexOf("Round " + "1" + " Draw")
          );
        } else {
          draw = this.tourinfo.reports.reports_entry.filter(
            tourinfo =>
              !tourinfo.report_title.indexOf("Round " + this.rPlayed + " Draw")
          );
        }
      else draw = "none";
      return draw;
    },
    filterScore: function(score) {
      if (Array.isArray(this.tourinfo.reports.reports_entry))
        if (this.rPlayed == "0") {
          score = this.tourinfo.reports.reports_entry.filter(
            tourinfo =>
              !tourinfo.report_title.indexOf("Round " + "1" + " Scores Only")
          );
        } else if (this.rPlayed == "1") {
          score = this.tourinfo.reports.reports_entry.filter(
            tourinfo =>
              !tourinfo.report_title.indexOf(
                "Round " + this.rPlayed + " Scores Only"
              )
          );
        } else {
          score = this.tourinfo.reports.reports_entry.filter(
            tourinfo =>
              !tourinfo.report_title.indexOf(
                "Round " + this.rPlayed + " Scoreboard"
              )
          );
        }
      else score = "none";
      // console.log("score")
      // console.log(score)
      return score;
    },
    filterFinal: function(final) {
      if (Array.isArray(this.tourinfo.reports.reports_entry))
        final = this.tourinfo.reports.reports_entry.filter(
          tourinfo => !tourinfo.report_title.indexOf("Final")
        );
      else final = "none";
      return final;
    }
  },
  created() {
    // this.autoScroll();
  }
};
</script>

<style scoped>
.parRight {
  padding-left: 30px;
}
.sponsorDesk {
  max-width: 200px;
}
::v-deep #app {
  scroll-behavior: smooth;
}
.reportsListMob {
  margin-top: 50px;
}
.btnColor {
  color: #fff;
  background-color: #003C52;
  display: block;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 2px;
  margin-top: 2px;
  font-size: 14px;
}
.btnColor:hover {
  color: #fff;
  background-color: #74DBBB;
  display: block;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 2px;
  margin-top: 2px;
  font-size: 14px;
}
.btnColor:hover > a{
  color: #000!important;
}
.tournDesc {
  font-size: 14px;
}
.tRowTopMainnonMobile {
  background-color: transparent!important;
}
.tRowTopMain {
  background-color: rgba(0, 0, 0, 0.04);
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #74DBBB;
    border-color: #74DBBB;
}
button.btn.enter.btn-secondary:focus {
  /* border-color: #74DBBB; */
  box-shadow: 0 0 0 0rem rgba(130, 138, 145, 0.5);
}
.hbhSpace {
   margin-top: 50px;
}
.leftsideText {
  /* margin-top: 100px; */
  margin-top: 50px;
  color: #fff;
}
.topBackHeader {
  background-color: #74DBBB;
}
button.btn.enter.disableEntry.btn-secondary.disabled {
  background-color: #c7c5c5;
  color: #000;
  cursor: not-allowed;
  opacity: .6;
  border-color: transparent;
}
::v-deep .overview > span > div > a > img{
  width: 100%;
  height: auto;
}
.colPadRight {
  /* padding-right: 26px; */
}
.PadRightNone {
  padding-right: 0px;
}
.overflowPad {
  /* padding: 15px; */
}
.enterTvguide {
  padding-right: 0;
}
::v-deep img.aligncenter.wp-image-19036.size-full {
  margin-right: auto;
  margin-left: auto;
  display: block;
  clear: both;
  margin-top: 3em;
}
.reportButton > a {
  /* padding-bottom: 0px; */
  padding-top: 10px;
  margin-bottom: 2px;
  font-size: 14px;
}
.reportsRight {
  margin-bottom: 1px;
  /* padding-left: calc(1.4vw + 3vh);
  padding-right: calc(1.8vw + 3vh); */
}
.holebyHole {
  /* padding-left: 3em; */
}
::v-deep h2 {
  font-size: 19px;
}
h3.valuesize {
  font-size: 14px;
}
.colTournInfo {
  background-color: rgba(0, 67, 62,0.9);
  padding-top: 1.2em;
  padding-bottom: 0.4em;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -1.3555em;
}
h2.tournInfo {
  font-size: 19px;
  position: relative;
  top: -2px;
}
.tour{
  /* background-color: #f9f9f9; */
  padding-bottom: 59px;
}
.rightside-changes {
  margin-bottom: 20px;
}
.tRow {
  border-bottom: 0px solid #000;
  padding: 15px 0;
  background: rgba(0, 0, 0, 0.04);
}
.over{
  text-align: center;
  background-color: #d4d4d4;
  padding-top: 25px;
  padding-bottom: 10px;
}
.fullName-width {
  font-size: 20px;
  font-family: 'Gantari', sans-serif!important;
  font-weight: 500 !important;
}
.fullName {
  font-size:40px;
  font-family: 'Gantari', sans-serif!important;
  font-weight: 600 !important;
  padding-bottom: 30px;
  color: #fff;
}
.courseName {
  font-size: 20px;
  line-height: 1;
}
.splash {
  width: 100%;
  height: 395px;
  /* margin-top: 40px; */
}
.enter{
  width: 100%;
  text-align: left;
  background-color: #003C52;
  border-radius: 0;
  margin-top: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
}
.enter:hover{
  width: 100%;
  text-align: left;
  background-color: #74DBBB;
  border-color: #74DBBB;
  border-radius: 0;
  margin-top: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
}
.tv{
  width: 100%;
  text-align: left;
  background-color: #00433e;
  border-radius: 0;
  padding-top: 10px;
  /* padding-bottom: 0px; */
}
.icon{
  float: right;
  margin-top: 3px;
  font-size: 13pt;
}
.courseInfo {
  font-weight: 600;
  font-size: 17px;
  color: #003C52;
}

.courseinfo {
  width: 100%;
}

.splashText {
  margin-top: -26%;
  color: white;
  padding-left: 30px;
}

.logo {
  margin-top: 50px;
  max-width: 200px;
  float: right;
  /* max-width: 40%;
  margin-top: 15px;
  float: right;
  margin-right: 20px; */
}

.overlay {
  padding: 25px;
  background: rgba(0, 0, 0, 0.5);
}

.splashPad {
  text-align: center;
  color: white;
  background-color: rgba(0, 67, 62, 1);
  margin-top: 128px;
  padding-top: 5px;
  padding-bottom: 3px;
}

.MainInfo {
  /* padding-left: calc(1.4vw + 3vh); */
  padding-top:40px;
  /* padding-right: calc(1.8vw + 3vh); */
  padding-bottom: 40px;
}

.nav-link {
  /* background-color: #003C52;  */
  color: white!important;
  text-align: left;
  /* padding-left: 12px; */
}
.nav-link:hover {
  background-color: #74DBBB;
  color: white!important;
  text-align: left;
  /* padding-left: 12px; */
}

.courseStats {
  /* padding-left: 4.5%; */
  /* padding-right: 4%; */
  text-align: center;
  padding-bottom: 60px;
}

.title {
  background-color: #75dbbb;
  color: white;
}

table,
th,
td {
  /* border: 1px solid black; */
    border: 1px solid rgba(0, 0, 0, 0.125);
  /* padding-top: 10px; */
  font-size: 14px;
}

.rowPad {
  padding-bottom: 1%;
}

.reportButton {
  /* font-size: calc(0.5vh + 0.5vw); */
  display: block;
  margin-bottom: 15px;
}

.mainImg {
  max-width: 75%;
}

#gmap_canvas {
  height: 300px;
  width: 350px;
}
.desc {
  font-size: 15px;
  margin-top: 2em;
}
/* @media only screen and (max-width: 1200px) {
  .splashPad {
    text-align: center;
    color: white;
    background-color: rgba(0, 67, 62, 1);
    margin-top: 21px;
    padding-top: 5px;
    padding-bottom: 3px;
  }
  .holebyHole {
    margin-left: 2.2em;
    padding-left: 0px;
  }
} */
@media only screen and (min-width: 1201px) {
  .showMobSec {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .showDeskSec {
    display: none;
  }
  .noshow {
    display: none;
  }
}
@media only screen and (max-width: 1199px) {
  .splashPad {
    text-align: center;
    color: white;
    background-color: rgba(0, 67, 62, 1);
    margin-top: 21px;
    padding-top: 5px;
    padding-bottom: 3px;
  }
  .holebyHole {
    /* margin-left: 2.2em; */
    padding-left: 0px;
  }
  .reportButton {
    width: auto;
  }
}
@media only screen and (max-width: 1024px) {
  .fullName-width {
    font-size: 20px;
    font-family: 'Gantari', sans-serif!important;
    font-weight: 500 !important;
    color: #fff;
  }
  .splash {
    height: 300px;
  }
  .splashText {
    margin-top: -42%;
  }

  .logo {
    margin-left: -30%;
    padding-top: 20%;
  }
  .splashPad[data-v-14fba303] {
    padding-top: 8%;
  }
  h2 {
    font-size: 19px;
    color: #00433e;
  }
  h3 {
    font-size: 1.3em;
  }
  h4 {
    font-size: 1em;
  }
  .desc {
    margin-left: -100%;
    font-size: 15px;
    margin-top: 2em;
  }
}
@media only screen and (min-width: 991px) {
  .showMobile {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  .showDesktop {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .holebyHole {
    margin-left: 0em;
    padding-left: 0px;
  }
  .enter  {
    width: 100%;
    text-align: left;
    background-color: #003C52;
    border-radius: 0;
    margin-top: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    /* margin-bottom: 10px; */
  }
  .logo {
    float: left;
    /* width: 160px !important; */
    margin: auto;
    /* text-align: center; */
    padding-top: 0;
    margin-top: 50px;
  }
  .col-sm-12.col-6 {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
  .PadRightNone{
    padding-right: 15px;
  }
  .fullName {
    font-size: 33px;
    font-family: 'Gantari', sans-serif!important;
    font-weight: 600 !important;
    /* padding-bottom: 30px; */
    padding-bottom: 15px;
  }
  p.courseName {
    padding-bottom: 20px;
  }
  .leftsideText {
    margin-top: 30px!important;
  }
  .topBackHeader {
    padding-bottom: 30px;
  }
  .hbhSpace {
    margin-top: 50px;
    display: none;
  }
  .courseStats {
    padding-left: 0!important;
    padding-right: 0!important;
  }
  .splashText {
    margin-top: -58%;
  }
  ::v-deep img.aligncenter.wp-image-19036.size-full {
    margin-right: auto;
    margin-left: auto;
    display: block;
    clear: both;
    margin-top: 3em;
    width: 100%!important;
  }
}

@media only screen and (max-width: 520px) {
  ::v-deep .overlay {
    padding: 10px!important;
  }
}
@media only screen and (max-width: 480px) {
  .splash {
    height: 175px;
  }
  .splashText {
    margin-top: 0;
    text-align: center;
  }
  .overlay {
    flex-basis: auto;
    background-color: #263056;
    color: white;
  }
  .logo {
    width: 50%;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: 0;
  }
  h2 {
    font-size: 1.7em;
  }
  h3 {
    font-size: 1.5em;
  }
  h4 {
    font-size: 1.3em;
  }
  .reportButton {
    font-size: 1em;
  }
  .reportRow {
    padding-bottom: 10%;
  }
}
</style>
