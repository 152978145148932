<template>
  <div class="playerprofile">

    <div class="tRowTopMain" :class="{tRowTopMainnonMobile : isMobile(true) }">
        <MiniMenu 
          :config="config"
          :seasonCode="season"
          :tour="tour"
        />
    </div>
    <div :style="backgroundColor">
      <b-container class=''>
        <!-- <div class="ad" style="text-align:center; height: 150px;">
          Advert
        </div> -->
        <b-row>
          <b-col>
            <div class="NameBlock">
              <span class="fullName">{{ playerinfo.MEMBER.FULL_NAME }}</span>
              <img
                class="flagPlayerProfile"
                :src="
                  (config.VUE_APP_FLAG_URL +
                    playerinfo.MEMBER.NATIONALITY)
                    | lowercase
                "
              />
                  <span class="socialLinks">
                    <span v-if="playerinfo.MEMBER.FACEBOOK.length">
                      <b-nav-item
                        :href="
                          'https://facebook.com/' + playerinfo.MEMBER.FACEBOOK
                        "
                      >
                        <font-awesome-icon
                          class="icon"
                          :icon="['fab', 'facebook-square']"
                        />
                      </b-nav-item>
                    </span>

                    <span v-if="playerinfo.MEMBER.TWITTER.length">
                      <b-nav-item
                        :href="'https://twitter.com/' + playerinfo.MEMBER.TWITTER"
                      >
                        <font-awesome-icon
                          class="icon"
                          :icon="['fab', 'twitter']"
                        />
                      </b-nav-item>
                    </span>

                    <span v-if="playerinfo.MEMBER.INSTAGRAM.length">
                      <b-nav-item
                        :href="
                          'https://instagram.com/' + playerinfo.MEMBER.INSTAGRAM
                        "
                      >
                        <font-awesome-icon
                          class="icon"
                          :icon="['fab', 'instagram']"
                        />
                      </b-nav-item>
                    </span>
                  </span> 
            </div>
          </b-col>
          <b-col class="col-md-4 profileImage" v-bind:style="{ 'background-image': 'url(' + config.VUE_APP_PLAYER_PHOTO_URL + refno + '.jpg' + ')' }">
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class='white'>
      <b-container class='playerData'>
        <div class="firstSection">
          <b-row class="playerProfileRow">
            <b-col lg="3" class="infoSpace" v-if="playerinfo.MEMBER.DATE_OF_BIRTH.length">
              <h3 class="playerProfileData">
                {{ playerinfo.MEMBER.DATE_OF_BIRTH }}
              </h3>
              <h4 class="playerProfileTitle">
                DATE OF BIRTH
              </h4>
            </b-col>
            <b-col lg="3" class="infoSpace">
                <h3 class="playerProfileData">
                  <span v-if="playerinfo.MEMBER.DATE_TURNED_PRO.length">{{ playerinfo.MEMBER.DATE_TURNED_PRO }}</span>
                  <span v-else>N/A</span>
                </h3>

              <h4 class="playerProfileTitle">
                TURNED PROFESSIONAL
              </h4>
            </b-col>
            <b-col lg="3" class="infoSpace">
              <h3 class="playerProfileData">
                <span v-if="playerinfo.MEMBER.COACH_NAME.length">{{ playerinfo.MEMBER.COACH_NAME }}</span>
                <span v-else>N/A</span>
              </h3>
              <h4 class="playerProfileTitle">
                COACH
              </h4>
            </b-col>
            <b-col lg="3" class="infoSpace">
              <h3 class="playerProfileData">
                <span v-if="playerinfo.MEMBER.PLACE_OF_BIRTH.length">{{ playerinfo.MEMBER.PLACE_OF_BIRTH }}</span>
                <span v-else>N/A</span>
              </h3>
              <h4 class="playerProfileTitle">
                PLACE OF BIRTH
              </h4>
            </b-col>
            <b-col lg="3" class="infoSpace">
              <h3 class="playerProfileData">
                <span v-if="playerinfo.MEMBER.RESIDENCE.length">
                  {{ playerinfo.MEMBER.RESIDENCE }}
                </span>
                <span v-else>
                  N/A
                </span>
              </h3>
              <h4 class="playerProfileTitle">
                RESIDENCE
              </h4>
            </b-col>
            <b-col lg="3" class="infoSpace">
              <h3 class="playerProfileData">
                <span v-if="enhancedPlayerInfo.tour_name.length">{{ enhancedPlayerInfo.tour_name }}</span>
                <span v-else>N/A</span>
              </h3>
              <h4 class="playerProfileTitle">
                CURRENT TOURS
              </h4>
            </b-col>
            <b-col lg="3" class="infoSpace">
              <h3 class="playerProfileData">
                <a class="owgrColor" :href="'http://www.owgr.com/en/Ranking/PlayerProfile.aspx?playerID=' + playerinfo.MEMBER.WGR_REF" target="_blank">
                  {{ playerinfo.MEMBER.OWGR_RANKING }}
                </a>
              </h3>
              <h4 class="playerProfileTitle">
                OWGR
              </h4>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col>
            <p class="bioTitle">
              Biography
            </p>
            <p v-if="playerinfo.BIOGRAPHY.length" class="bio">
              {{ playerinfo.BIOGRAPHY }}
            </p>
            <p v-else class="bio">
              No Information
            </p>
            <p v-if="playerinfo.BIOGRAPHY2.length" class="bio">
              {{ playerinfo.BIOGRAPHY2 }}
            </p>
            <p v-if="playerinfo.BIOGRAPHY3.length" class="bio">
              {{ playerinfo.BIOGRAPHY3 }}
            </p>
          </b-col>
        </b-row>
        <b-row class="tableSection">
          <b-col>
            <b-tabs content-class="mt-3" class="playerblock">
              <b-tab :title="'Results ' + season" active>
                <PlayerResults
                  :data='playerinfo.THIS_SEASON_RECORD.TOURNAMENT'
                  :config="config"
                />
              </b-tab>
              <b-tab title="Career Highlights">
                <PlayerCareerHighlights
                  :stats="playerinfo.CAREER_HIGHLIGHTS.TOURNAMENT"
                  :config="config"
                />
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import MiniMenu from "@/components/miniMenu.vue";
import axios from "axios";
import PlayerResults from "@/components/playerResults.vue";
import PlayerCareerHighlights from "@/components/playerCareerHighlights.vue";
export default {
  name: "playerprofile",
  components: {
    PlayerResults,
    PlayerCareerHighlights,
    MiniMenu
  },
  props: ["refno", "season", 'config'],
  data() {
    return {
      playerinfo: [],
      enhancedPlayerInfo: [],
      tour: process.env.VUE_APP_TOUR,
      backgroundColor: {
        backgroundColor: process.env.VUE_APP_REPORT_MAIN_COLOUR,
        color: '#fff'
      }
    };
  },
  methods: {
    isMobile() {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    },
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_PLAYER_BASIC +
          this.refno +
          "?randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        this.playerinfo = response.data;
        return axios.get(
          process.env.VUE_APP_PLAYER_ENCHANCED +
            this.season +
            "/" +
            this.season +
            '-players-' +
            this.refno +
            '.json'+
            "?randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.enhancedPlayerInfo = response.data;
        this.playerName = response.data.full_name;
        localStorage.setItem("Player Name",  this.playerName);
        localStorage.setItem("Player Link",  this.$route.fullPath);
      });
  },
};
</script>

<style scoped>
.tableSection {
  margin-top: 30px;
}
.tRowTopMainnonMobile {
  background-color: transparent!important;
}
.tRowTopMain {
  background-color: rgba(0, 0, 0, 0.04);
}
.infoSpace {
  padding-bottom: 50px;
}
.NameBlock {
  margin-top: 150px;
}
.firstSection {
  padding-bottom: 50px;
}
span.fullName {
  font-size: 60px;
  font-family: 'Gantari', sans-serif !important;
  font-weight: 600 !important;
}
.profileImage {
  height: 395px;
  background-position: center;
  background-size: cover;
}
.TopGreen {
  background-color: #74DBBB;
}
.tRow {
  border-bottom: 0px solid #000;
  padding: 15px 0;
  background: rgba(0, 0, 0, 0.04);
}
a.owgrColor {
  color: #00b588!important;
}
a.owgrColor:hover {
  color: #000!important;
}
::v-deep .playerblock > div > ul {
  border-bottom: 0px!important;
}
::v-deep .mt-3, .my-3 {
  margin-top: 0rem !important;
}
.playerprofile{
  /* background-color: #F9f9f9; */
  padding-bottom: 50px;
}
.playerData{
  padding: 50px 0;
}
.titleCont {
  position: relative;
  top: 49%;
  transform: translateY(-50%);
  text-align: center;
}
li {
  list-style-type: none;
}
.playerName {
  color: #000;
  font-size: 22pt;
  width: 100%;
  display: block;
  margin-top: 0em;
  border-bottom: 1px solid #ddd;
  padding-bottom: 2px;
}
.flagPlayerProfile {
  max-height: 20px;
  box-shadow: 0px 0px 3px #555;
  margin-left: 25px;
  margin-bottom: 10px;
  border: 0.1px solid;
}
.icon {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.socialLinks {
  /* float: right; */
}
span.socialLinks > span > li >a {
  padding-left: 5px;
}
.icon {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 25px;
  color: #fff;
}
.playerProfileData {
  font-weight: 700;
  font-size: 17px;
  color: #003C52;
}
.playerProfileTitle {
  color: #565656;
  font-size: 10pt;
  text-transform: uppercase;
  margin: 0;
}
.playerProfileRow {
  padding: 20px;
  padding-left: 0px;
  padding-right: 0px;
}
.playerImg {
  width: 100%;
}
.bioTitle {
  /* padding-left: 13px; */
  font-weight: 700;
  font-size: 17px;
  color: #003C52;
}
.bio {
  color: #565656;
  font-size: 11pt;
  margin: 0 0 10px;
  font-family: "Roboto", sans-serif !important;
  line-height: 1.42857143;
  font-weight: 300;
}
::v-deep .nav-tabs > li > a.active {
  color: #fff !important;
  cursor: default;
  background-color: #00433e;
  border-bottom-color: transparent;
  border-radius: 0px;
  /* height: 42px; */
  width: 100%;
  border: none;
}
::v-deep .nav-tabs > li > a {
  color: #fff !important;
  background-color: #003C52;
  border-radius: 0px;
  padding: 12px 0 10px 0px;
  text-align: center;
  font-size: 14px;
  width: 100%;
  border: none;
  font-weight: 600;
}
::v-deep .nav-tabs > li > a:hover {
  color: #fff !important;
  background-color: #74DBBB;
  border-radius: 0px;
  padding: 12px 0 10px 0px;
  text-align: center;
  font-size: 14px;
  width: 100%;
  border: none;
}
::v-deep .nav-item {
  width: 50%;
}

.white{
  background-color: white;
}
</style>
