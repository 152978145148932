<template>
  <div id="hbh">
    <div class="table-responsive">
      <table class="tableMob">
        <thead v-bind:style="{ 'background-color':  reportColour}">
          <tr class="title">
            <th class="CP"></th>
            <th class="CN"></th>
            <th class="CF"></th>
            <th class="CT"></th>
            <th></th>
            <th>
              1
            </th>
            <th>
              2
            </th>
            <th>
              3
            </th>
            <th>
              4
            </th>
            <th>
              5
            </th>
            <th>
              6
            </th>
            <th>
              7
            </th>
            <th>
              8
            </th>
            <th>
              9
            </th>
            <th>
              Out
            </th>
            <th>
              10
            </th>
            <th>
              11
            </th>
            <th>
              12
            </th>
            <th>
              13
            </th>
            <th>
              14
            </th>
            <th>
              15
            </th>
            <th>
              16
            </th>
            <th>
              17
            </th>
            <th>
              18
            </th>
            <th>
              IN
            </th>
            <th>
              TOT
            </th>
          </tr>
          <template v-if="data.multi_course === 'Y'">
            <tr
              :class="multi.course_X_colour + 'row'"
              v-for="(multi, index) in data.courses.courses_entry"
              :key="index"
            >
              <th class="CP">
                {{ multi.course_X_id }}
              </th>
              <th class="CN hbhName">
                <span v-if="index === 1">
                  Player Name
                </span>
              </th>
              <th class="CF">
                <span v-if="index === 1">
                  Nat.
                </span>
              </th>
              <th class="CP">
                <span v-if="index === 1">
                  Tot
                </span>
              </th>
              <th class="CP">
                <span v-if="index === 1">
                  VsPar
                </span>
              </th>
              <th
                class="CP"
                v-for="(course, index) in multi.course_X_par
                  .split(',')
                  .slice(0, 9)"
                :key="index + Math.random()"
              >
                {{ course }}
              </th>
              <th class="CP">
                {{ multi.course_X_out_par.slice(1) }}
              </th>
              <th
                class="CP"
                v-for="(course, index) in multi.course_X_par
                  .split(',')
                  .slice(9, 18)"
                :key="index + Math.random()"
              >
                {{ course }}
              </th>
              <th class="CP">
                {{ multi.course_X_in_par.slice(1) }}
              </th>
              <th class="CP">
                {{ multi.course_X_total_par.slice(1) }}
              </th>
            </tr>
          </template>
          <tr v-else class="title">
            <th class="CP">
              Pos
            </th>
            <th class="CN hbhName">
              Player Name
            </th>
            <th class="CF">
              Nat.
            </th>
            <th class="CT">
              Tot
            </th>
            <th>
              VsPar
            </th>
            <th
              v-for="(course, index) in data.course_par.split(',').slice(0, 9)"
              :key="index + Math.random()"
            >
              {{ course }}
            </th>
            <th>
              {{ data.course_out_par.slice(1) }}
            </th>
            <th
              v-for="(course, index) in data.course_par.split(',').slice(9, 18)"
              :key="index + Math.random()"
            >
              {{ course }}
            </th>
            <th>
              {{ data.course_in_par.slice(1) }}
            </th>
            <th>
              {{ data.course_total_par }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="RT"
            v-for="(playerData, index) in scores"
            :key="index + Math.random()"
          >
            <td v-if="playerData.pos !== 'CUT'">
              <span
                v-if="
                  index !== 0
                "
                class="CP pos"
              >
                {{ playerData.tied + playerData.pos }}
              </span>
              <span v-else-if="index === 0" class="CP pos">
                {{ playerData.tied + playerData.pos }}
              </span>
              <span v-else></span>
            </td>
            <td
              class="hbhName"
              v-if="playerData.pos !== 'CUT'"
              :title="playerData.member_no"
              :class="{
                'text-up': playerData.posChang == 'up',
                'text-down': playerData.posChang == 'down',
              }"
            >
              <a
                :href="
                  '/playerprofile/' + playerData.id + '/' + $route.query.id
                "
              >
                {{ playerData.name }}</a
              >
              <span v-if="data.multi_course === 'Y'">
                <span :class="playerData['course_colour_R' + data.round]">
                  •
                </span>
              </span>
            </td>
            <td
              v-if="playerData.pos !== 'CUT'"
              class="CF"
              :title="playerData.nationality"
            >
              <span>
                <img
                  class="flag"
                  :src="
                    ('https://assets.ocs-sport.com/flags/svg/flag_' +
                      playerData.nationality)
                      | lowercase
                  "
                />
              </span>
            </td>
            <td v-if="playerData.pos !== 'CUT'" class="CT">
              <span v-if="playerData.vspar < 0" :class="up">
                {{ playerData.vspar }}
              </span>
              <span v-else-if="playerData.vspar > 0" :class="down">
                {{ playerData.vspar }}
              </span>
              <span v-else>
                {{ playerData.vspar }}
              </span>
            </td>
            <td v-if="playerData.pos !== 'CUT'">
              <span
                v-if="playerData['vspar_R' + data.pft_round] < 0"
                :class="up"
              >
                {{ playerData["vspar_R" + data.pft_round] }}
              </span>
              <span
                v-else-if="playerData['vspar_R' + data.pft_round] > 0"
                :class="down"
              >
                {{ playerData["vspar_R" + data.pft_round] }}
              </span>
              <span v-else>
                {{ playerData["vspar_R" + data.pft_round] }}
              </span>
            </td>
            <td class="cut" colspan="30" v-if="playerData.pos === 'CUT'">
              {{ playerData.pos }} {{ playerData.cut_pft_round }}
            </td>
            <td
              class="CS"
              v-for="(r1, index) in (
                playerData['hole_scores_R' + data.pft_round] || ''
              )
                .split(',')
                .slice(0, 9)"
              :key="index + Math.random()"
               v-show="playerData.pos !== 'CUT'"
            >
              <span
                class="twoUnder"
                v-if="
                  (playerData['hole_vspars_R' + data.pft_round] || '').split(
                    ','
                  )[index] == -2
                "
              >
                {{ r1 }}
              </span>
              <span
                class="oneUnder"
                v-else-if="
                  (playerData['hole_vspars_R' + data.pft_round] || '').split(
                    ','
                  )[index] == -1
                "
              >
                {{ r1 }}
              </span>
              <span
                class="oneOver"
                v-else-if="
                  (playerData['hole_vspars_R' + data.pft_round] || '').split(
                    ','
                  )[index] == 1
                "
              >
                {{ r1 }}
              </span>
              <span
                class="twoOver"
                v-else-if="
                  (playerData['hole_vspars_R' + data.pft_round] || '').split(
                    ','
                  )[index] == 2
                "
              >
                {{ r1 }}
              </span>
              <span v-else>
                {{ r1 }}
              </span>
            </td>
            <td class="CS" v-if="playerData.pos !== 'CUT'">
              <span
                v-if="
                  sum(
                    (playerData['hole_scores_R' + data.pft_round] || '')
                      .split(',')
                      .slice(0, 9)
                  ) > data.course_out_par
                "
                class="down"
              >
                {{
                  sum(
                    (playerData["hole_scores_R" + data.pft_round] || "")
                      .split(",")
                      .slice(0, 9)
                  )
                }}
              </span>
              <span
                v-else-if="
                  sum(
                    (playerData['hole_scores_R' + data.pft_round] || '')
                      .split(',')
                      .slice(0, 9)
                  ) < data.course_out_par
                "
                class="up"
              >
                {{
                  sum(
                    (playerData["hole_scores_R" + data.pft_round] || "")
                      .split(",")
                      .slice(0, 9)
                  )
                }}
              </span>
              <span v-else>
                {{
                  sum(
                    (playerData["hole_scores_R" + data.pft_round] || "")
                      .split(",")
                      .slice(0, 9)
                  )
                }}
              </span>
            </td>
            <td
              class="CS"
              v-for="(r1, index) in (
                playerData['hole_scores_R' + data.pft_round] || ''
              )
                .split(',')
                .slice(9, 18)"
              :key="index + Math.random()"
              v-show="playerData.pos !== 'CUT'"
            >
              <span
                class="twoUnder"
                v-if="
                  (playerData['hole_vspars_R' + data.pft_round] || '')
                    .split(',')
                    .slice(9, 18)[index] == -2
                "
              >
                {{ r1 }}
              </span>
              <span
                class="oneUnder"
                v-else-if="
                  (playerData['hole_vspars_R' + data.pft_round] || '')
                    .split(',')
                    .slice(9, 18)[index] == -1
                "
              >
                {{ r1 }}
              </span>
              <span
                class="oneOver"
                v-else-if="
                  (playerData['hole_vspars_R' + data.pft_round] || '')
                    .split(',')
                    .slice(9, 18)[index] == 1
                "
              >
                {{ r1 }}
              </span>
              <span
                class="twoOver"
                v-else-if="
                  (playerData['hole_vspars_R' + data.pft_round] || '')
                    .split(',')
                    .slice(9, 18)[index] == 2
                "
              >
                {{ r1 }}
              </span>
              <span v-else>
                {{ r1 }}
              </span>
            </td>
            <td class="CS"  v-if="playerData.pos !== 'CUT'">
              <span
                v-if="
                  sum(
                    (playerData['hole_scores_R' + data.pft_round] || '')
                      .split(',')
                      .slice(9, 18)
                  ) > data.course_in_par
                "
                class="down"
              >
                {{
                  sum(
                    (playerData["hole_scores_R" + data.pft_round] || "")
                      .split(",")
                      .slice(0, 9)
                  )
                }}
              </span>
              <span
                v-else-if="
                  sum(
                    (playerData['hole_scores_R' + data.pft_round] || '')
                      .split(',')
                      .slice(9, 18)
                  ) < data.course_in_par
                "
                class="up"
              >
                {{
                  sum(
                    (playerData["hole_scores_R" + data.pft_round] || "")
                      .split(",")
                      .slice(9, 18)
                  )
                }}
              </span>
              <span v-else>
                {{
                  sum(
                    (playerData["hole_scores_R" + data.pft_round] || "")
                      .split(",")
                      .slice(9, 18)
                  )
                }}
              </span>
            </td>
            <td v-if="data.rounds_played > 1 && playerData.pos !== 'CUT'" class="CS">
              <span
                v-if="
                  playerData['hole_scores_R' + data.pft_round] >
                    data.course_total_par
                "
                class="down"
              >
                {{ playerData["score_R" + data.pft_round] }}
              </span>
              <span
                v-else-if="
                  playerData['hole_scores_R' + data.pft_round] <
                    data.course_total_par
                "
                class="up"
              >
                {{ playerData["score_R" + data.pft_round] }}
              </span>
              <span v-else>
                {{ playerData["score_R" + data.pft_round] }}
              </span>
            </td>
            <td v-else class="CS" v-show="playerData.pos !== 'CUT'">
              <span
                v-if="playerData.score > data.course_total_par"
                class="down"
              >
                {{ playerData.score }}
              </span>
              <span
                v-else-if="playerData.score < data.course_total_par"
                class="up"
              >
                {{ playerData.score }}
              </span>
              <span v-else>
                {{ playerData.score }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "hbh",
  props: ["data", "scores", "previousData"],
  data() {
    return {
      up: "up",
      down: "down",
      CS: "CS",
      CV: "CV",
      CT: "CT",
      CN: "CN",
      CP: "CP",
      CF: "CF",
      reportColour: process.env.VUE_APP_REPORT_MAIN_COLOUR
    };
  },
  computed: {
    roundsPlayer: function() {
      return parseInt(this.data.pft_round);
    },
  },
  watch: {
    scores: {
      immediate: true,
      handler(newValue, oldValue) {
        newValue.forEach((value1, key1) => {
          oldValue.forEach((value2, key2) => {
            if (value1.id === value2.id) {
              if (key1 < key2) {
                value1.posChang = "up";
              } else if (key1 > key2) {
                value1.posChang = "down";
              }
            }
          });
        });

        console.log(newValue);
      },
      deep: true,
    },
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
  methods: {
    sum: function(nums) {
      let result = 0;
      nums.forEach(function(n) {
        result += n * 1;
      });
      if (result == 0) {
        result = ''
      } else {
        return result; 
      }
    }
  },
};
</script>

<style scoped>
::v-deep .hbhName > a {
    color: #000!important;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    top: -6px;
}
::v-deep .hbhName > a:hover {
    color: rgb(51, 203, 169)!important;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    position: relative;
    top: -6px;
}
tbody {
    color: #000;
    font-size: 14px;
    font-weight: 600;
}
.hbhName {
  text-align: left;
  padding-left: 20px!important;
}
#hbh {
  /* padding-top: 2%;
  padding-bottom: 2%; */
}

.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.oneUnder {
  color: #fff !important;
  background-color: #a52a2a !important;
  padding: 10px;
}

.twoUnder {
  color: #fff !important;
  background-color: #3c9933 !important;
  padding: 10px;
}

.oneOver {
  color: #fff !important;
  background-color: #1e90ff !important;
  padding: 10px;
}

.twoOver {
  color: #fff !important;
  background-color: #e07020 !important;
  padding: 10px;
}

.cut {
  background-color: red;
  color: white;
}
.title {
  /* background-color: #00433e !important; */
  color: white;
}
::v-deep table > tbody > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 0px solid #ddd;
  border-right: none !important;
  /* padding: 0.8em 0.5em 0.8em 1em !important; */
  cursor: pointer;
  font-size: 13px;
  text-align: center;
  padding-top: 13px;
}
::v-deep table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 0px solid #ddd;
  font-weight: bold;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  /* background: #00433e; */
  color: #ffffff !important;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
table {
  width: 100%;
}
.CV {
  /* border: 1px solid black; */
}
.CS {
  /* border: 1px solid black; */
}
.Rrow {
  border-bottom: 1px solid #bb0000 !important;
  background-color: #bb0000!important;
}
.Grow {
  border-bottom: 1px solid #3c9933 !important;
  background-color: #3c9933!important;
}
.Brow {
  border-bottom: 1px solid #333333 !important;
  background-color: #333333!important;
}
.Yrow {
  border-bottom: 1px solid #d8db22 !important;
  background-color: #d8db22!important;
}
.Crow {
  border-bottom: 1px solid #1E90FF !important;
  background-color: #1E90FF!important;
}
.Orow {
  border-bottom: 1px solid #e07020 !important;
  background-color: #e07020!important;
}
.B {
  color: #333333;
  font-size: 43px !important;
  line-height: .2;
  position: relative;
  top: 3px;
}
.Y {
  color: #d8db22;
  font-size: 43px !important;
  line-height: .2;
  position: relative;
  top: 3px;
}
.C {
  color: #1E90FF;
  font-size: 43px !important;
  line-height: .2;
  position: relative;
  top: 3px;
}
.R {
  color: #BB0000;
  font-size: 43px !important;
  line-height: .2;
  position: relative;
  top: 3px;
}
.G {
  color: #3c9933;
  font-size: 43px !important;
  line-height: .2;
  position: relative;
  top: 3px;
}
.O {
  color: #e07020;
  font-size: 43px !important;
  line-height: .2;
  position: relative;
  top: 3px;
}
</style>
